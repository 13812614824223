var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateAWS.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Is production","label-for":"is_production-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-form-radio-group',{attrs:{"options":_vm.statusOptions},model:{value:(_vm.form.s3.is_production),callback:function ($$v) {_vm.$set(_vm.form.s3, "is_production", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.s3.is_production"}})],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Access ID","label-for":"access-ID-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.form.s3.access_key.$error,
        },attrs:{"placeholder":"access ID","state":_vm.validateState('access_key')},model:{value:(_vm.form.s3.access_key),callback:function ($$v) {_vm.$set(_vm.form.s3, "access_key", $$v)},expression:"form.s3.access_key"}}),(_vm.submitted && !_vm.$v.form.s3.access_key.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" access_key is required ")]):_vm._e()],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Secret Key","label-for":"secret-key-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.form.s3.secret_key.$error,
        },attrs:{"placeholder":"secret key","state":_vm.validateState('secret_key')},model:{value:(_vm.form.s3.secret_key),callback:function ($$v) {_vm.$set(_vm.form.s3, "secret_key", $$v)},expression:"form.s3.secret_key"}}),(_vm.submitted && !_vm.$v.form.s3.secret_key.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" secret_key is required ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Region","label-for":"region-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{attrs:{"placeholder":"region"},model:{value:(_vm.form.s3.region),callback:function ($$v) {_vm.$set(_vm.form.s3, "region", $$v)},expression:"form.s3.region"}})],1),_c('b-form-group',{staticClass:"mt-3",attrs:{"label":"Bucket Name","label-for":"bucket-input","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"7"}},[_c('b-input',{class:{
          'is-invalid': _vm.submitted && _vm.$v.form.s3.bucket.$error,
        },attrs:{"placeholder":"Bucket name","state":_vm.validateState('bucket')},model:{value:(_vm.form.s3.bucket),callback:function ($$v) {_vm.$set(_vm.form.s3, "bucket", $$v)},expression:"form.s3.bucket"}}),(_vm.submitted && !_vm.$v.form.s3.bucket.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" bucket is required ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-md-6 offset-md-4"},[_c('b-button',{staticClass:"btn btn-success btn-lg text-center",attrs:{"type":"submit"}},[(_vm.submitted)?_c('b-spinner',{attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v("Submit")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }