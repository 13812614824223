<template>
  <div>
    <router-link
      target="_blank"
      class="text-small forgot-password text-black mr-3 float-right"
      to="/privacy-policy"
      ><b-badge variant="primary">
        <i class="mdi mdi-eye"> </i> Preview</b-badge
      ></router-link
    >
    <b-form @submit.prevent="updateTerms">
      <input type="hidden" v-model="form.id" />
      <b-form-group
        label="Privacy Policy"
        label-for="term-input"
        class="mt-3"
        label-cols-sm="4"
        label-cols-lg="2"
        content-cols-sm
        content-cols-lg="10"
      >
        <vue-editor class="mt-3" v-model="form.privacypolicy"></vue-editor>
      </b-form-group>

      <b-form-group class="col-md-6 offset-md-4">
        <b-button type="submit" class="btn btn-success btn-lg text-center"
          ><b-spinner small v-if="submitted" label="Loading..."></b-spinner
          >Submit</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { settingService } from "../../services";
export default {
  name: "term",
  data() {
    return {
      form: {
        type: "privacypolicy",
        id: "",
        privacypolicy: "",
      },
      submitted: false,
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    async getSetting() {
      try {
        const response = await settingService.find("privacypolicy");
        if (response.status) {
          this.form = response.data;
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
          position: "top-right",
          duration: 5000,
        });
      }
    },
    async updateTerms() {
      try {
        this.submitted = true;
        const response = await settingService.update(this.form.id, this.form);
        if (response.status) {
          this.submitted = false;
          this.$toast.open({
            message: response.message,
            type: "success",
            position: "top-right",
            duration: 2000,
            // all of other options may go here
          });
        }
      } catch (e) {
        this.$toast.open({
          message: e,
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
  mounted() {
    this.getSetting();
  },
};
</script>

<style lang="scss" scoped></style>
